.testimonials-container {
    padding: 40px;
  }

  .testimonials-header h2 {
    color: #fff;
    margin-bottom: 20px;
    margin-top: 100px;
  }
  
  .testimonials-header p {
    color: #ccc;
    margin-bottom: 40px;
  }
  
  .consultation-button {
    background-color: #000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 20px;
    font-size: 1.5rem;
    margin-top: 50px;
    margin-bottom: 40px;
  }
  
  .consultation-button:hover {
    background-color: #333;
  }
  
  .testimonials-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  
  .testimonial-card {
    background: #21123ac0;
    padding: 20px;
    border-radius: 10px;
    color: #fff;
  }
  
  .testimonial-top {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  
  .testimonial-image {
    width: 60px; /* Or the size you want */
    height: 60px; /* Keep it the same as width for a circle */
    border-radius: 50%; /* Makes the image round */
    object-fit: cover; /* Ensures the image covers the area without stretching */
    margin-right: 15px;
  }
  
  .testimonial-info {
    text-align: left;
    height: fit-content;
  }

  .testimonial-info p{
    margin: 0;
  }
  
  .testimonial-description {
    display: flex;
    align-items: flex-start;
    text-align: start;
    font-size: 0.9rem;
    margin: 0;
  }
  